import { useMemo } from 'react';
import { getSelectedProducts, getStores, ProductCart, SlicerBasics } from '@slicekit/core';
import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { getConfig } from 'cb-wallet-data/scw/libs/wagmi/config';
import { usePageOutageContext } from 'cb-wallet-data/stores/PageOutage/PageOutageProvider';
import type { GetStaticProps, InferGetStaticPropsType } from 'next';
import { Group } from '@cbhq/cds-web/layout';

import { withFeatureEnabled } from ':dapp/components/withFeatureEnabled/withFeatureEnabled';
import { THE_GRAPH_KEY_INTERNAL } from ':dapp/config/env';
import { OCSTabKey, useOCSTabs } from ':dapp/pages/ocs/hooks/useOCSTabs';

import { OCSOutage } from './components/OCSOutage';
import OCSPlayPage from './play/index.page';
import OCSProgressPage from './progress/index.page';
import { allProducts, allRewards, getRandomProducts, partnersShops } from './shop/allowedProducts';
import OCSShopPage from './shop/index.page';
import OCSTodayPage from './today/index.page';

export const getStaticProps = (async ({ params, ...rest }) => {
  const shopRewardProducts = allRewards.map(({ slicerId, productId }) => ({
    slicerId,
    productIds: [productId],
  }));

  let buyNowProducts: ProductCart[] = [];
  let rewardProducts: ProductCart[] = [];
  let shops: SlicerBasics[] = [];

  try {
    [buyNowProducts, rewardProducts, shops] = await Promise.all([
      getSelectedProducts(getConfig(), {
        products: getRandomProducts(allProducts, 3),
        dynamicPricing: true,
        thegraphApiKey: THE_GRAPH_KEY_INTERNAL,
      }),
      getSelectedProducts(getConfig(), {
        products: shopRewardProducts,
        dynamicPricing: true,
        thegraphApiKey: THE_GRAPH_KEY_INTERNAL,
      }),
      getStores({ slicerIds: partnersShops.sort(() => 0.5 - Math.random()) }),
    ]);
  } catch (e) {
    cbReportError({
      error: coerceError(e, 'fetch slice products on server failed'),
      context: 'slice_query',
      severity: 'error',
      isHandled: true,
    });
  }

  return {
    props: {
      ...params,
      ...rest,
      buyNowProducts,
      rewardProducts,
      shops,
    },
    revalidate: 300,
  };
}) as GetStaticProps<{
  buyNowProducts?: ProductCart[];
  rewardProducts?: ProductCart[];
  shops?: SlicerBasics[];
}>;

function OCSPage({
  buyNowProducts,
  rewardProducts,
  shops,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const tabs = useOCSTabs();
  const { gamificationApiIssue, exploreApiIssue } = usePageOutageContext();

  return useMemo(() => {
    if (gamificationApiIssue && exploreApiIssue) {
      // Due to how the directories are rendered, the `/ocs/today` "tabs" are individual pages.
      // Therefore, each also needs their own logic for displaying general outage page
      return <OCSOutage />;
    }

    if (!tabs || tabs.length === 0) return null;

    let page = null;
    switch (tabs[0].id) {
      case OCSTabKey.TODAY:
        page = <OCSTodayPage products={buyNowProducts} />;
        break;
      case OCSTabKey.PLAY:
        page = <OCSPlayPage />;
        break;
      case OCSTabKey.PROGRESS:
        page = <OCSProgressPage />;
        break;
      case OCSTabKey.SHOP:
        page = (
          <OCSShopPage
            buyNowProducts={buyNowProducts}
            rewardProducts={rewardProducts}
            shops={shops}
          />
        );
        break;
      default:
        break;
    }

    // NuxWrapper wraps each page as opposed to the general because child routes DO NOT render this component (e.g. /ocs/today skips `OCSPage`)
    return <Group testID="ocs-page">{page}</Group>;
  }, [gamificationApiIssue, exploreApiIssue, tabs, buyNowProducts, rewardProducts, shops]);
}

export default withFeatureEnabled(OCSPage, 'ocs_pages');
