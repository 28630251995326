import { defineMessages, useIntl } from 'react-intl';
import { usePageOutageContext } from 'cb-wallet-data/stores/PageOutage/PageOutageProvider';
import { VStack } from '@cbhq/cds-web/layout';

import { withFeatureEnabled } from ':dapp/components/withFeatureEnabled/withFeatureEnabled';
import { ExperiencesFilters } from ':dapp/pages/ocs/play/components/ExperiencesFilters';

import { OCSExperienceOutage } from '../components/OCSExperienceOutage';
import { OCSOutage } from '../components/OCSOutage';
import { OCSPageWrapper } from '../components/OCSPageWrapper';
import { OCSSectionHeader } from '../components/OCSSectionHeader';

import { ChallengeGrid } from './components/ChallengeGrid';

const messages = defineMessages({
  ocsOnchainExperiencesSectionHeaderTitle: {
    defaultMessage: 'Onchain experiences',
    description: "section header title for Onchain Summer's Onchain experiences section",
  },
  ocsOnchainExperiencesSectionHeaderSubtitle: {
    defaultMessage:
      'Earn points for completing experiences, level up and unlock prizes in the store',
    description: "section header subtitle for Onchain Summer's Onchain experiences section",
  },
});

function OCSPlayPage() {
  const { formatMessage } = useIntl();
  const { gamificationApiIssue, exploreApiIssue } = usePageOutageContext();

  if (gamificationApiIssue && exploreApiIssue) {
    return <OCSOutage />;
  }

  return (
    <VStack testID="ocs-tab-play">
      <OCSPageWrapper>
        <OCSSectionHeader
          title={formatMessage(messages.ocsOnchainExperiencesSectionHeaderTitle)}
          subtitle={formatMessage(messages.ocsOnchainExperiencesSectionHeaderSubtitle)}
          spacingBottom={1}
        />
        <ExperiencesFilters />

        {exploreApiIssue ? <OCSExperienceOutage /> : <ChallengeGrid />}
      </OCSPageWrapper>
    </VStack>
  );
}

export default withFeatureEnabled(OCSPlayPage, 'ocs_play_tab');
