/* istanbul ignore file */

import { getSelectedProducts, getStores, ProductCart, SlicerBasics } from '@slicekit/core';
import { cbReportError, coerceError } from 'cb-wallet-data/errors/reportError';
import { getConfig } from 'cb-wallet-data/scw/libs/wagmi/config';
import { GetStaticProps, InferGetStaticPropsType } from 'next';

import { withFeatureEnabled } from ':dapp/components/withFeatureEnabled/withFeatureEnabled';
import { THE_GRAPH_KEY_INTERNAL } from ':dapp/config/env';
import OCSPage from ':dapp/pages/ocs/index.page';

import {
  allProducts,
  allRewards,
  getRandomProducts,
  partnersShops,
} from '../ocs/shop/allowedProducts';

export const getStaticProps = (async ({ params, ...rest }) => {
  const shopRewardProducts = allRewards.map(({ slicerId, productId }) => ({
    slicerId,
    productIds: [productId],
  }));

  let buyNowProducts: ProductCart[] = [];
  let rewardProducts: ProductCart[] = [];
  let shops: SlicerBasics[] = [];

  try {
    [buyNowProducts, rewardProducts, shops] = await Promise.all([
      getSelectedProducts(getConfig(), {
        products: getRandomProducts(allProducts, 3),
        dynamicPricing: true,
        thegraphApiKey: THE_GRAPH_KEY_INTERNAL,
      }),
      getSelectedProducts(getConfig(), {
        products: shopRewardProducts,
        dynamicPricing: true,
        thegraphApiKey: THE_GRAPH_KEY_INTERNAL,
      }),
      getStores({ slicerIds: partnersShops.sort(() => 0.5 - Math.random()) }),
    ]);
  } catch (e) {
    cbReportError({
      error: coerceError(e, 'fetch slice products on server failed'),
      context: 'slice_query',
      severity: 'error',
      isHandled: true,
    });
  }

  return {
    props: {
      ...params,
      ...rest,
      buyNowProducts,
      rewardProducts,
      shops,
    },
    revalidate: 300,
  };
}) as GetStaticProps<{
  buyNowProducts?: ProductCart[];
  rewardProducts?: ProductCart[];
  shops?: SlicerBasics[];
}>;

function OCSSummerPage({
  buyNowProducts,
  rewardProducts,
  shops,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  return <OCSPage buyNowProducts={buyNowProducts} rewardProducts={rewardProducts} shops={shops} />;
}

export default withFeatureEnabled(OCSSummerPage, 'ocs_pages');
