import { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';

import { CardWithImage } from ':dapp/components/Card/CardWithImage';
import { ContentComponent, OCSChallengeCard } from ':dapp/pages/ocs/types/ocsResponseTypes';
import { useExperienceModalContext } from ':dapp/providers/ExperienceModalProvider';
import { useShareModalContext } from ':dapp/providers/ShareModalProvider';

const messages = defineMessages({
  ocsOnchainExperiencesCtaPoints: {
    defaultMessage: '(+{points} points)',
    description:
      'indicator to the user for how many points they will earn by completing this challenge',
  },
});

export type OnchainExperienceProps = {
  card: ContentComponent;
};

export function OnchainExperienceCard({ card }: OnchainExperienceProps) {
  const { openExperienceModal } = useExperienceModalContext();
  const { openShareModal } = useShareModalContext();
  const challengeBadgesEnabled = useIsFeatureEnabled('ocs_challenge_badges');

  const { formatMessage } = useIntl();
  const content = card.ocsChallengeCard;
  const image = useMemo(() => ({ src: content.imageUrl }), [content]);
  const action = useMemo(
    () => ({
      text: `${content.ctaText}${
        content.points > 0
          ? ` ${formatMessage(messages.ocsOnchainExperiencesCtaPoints, { points: content.points })}`
          : ''
      }`,
      url: content.targetUrl,
    }),
    [content, formatMessage],
  );
  const badge = useMemo(
    () => (challengeBadgesEnabled ? { numCompletions: content.numCompletions } : undefined),
    [content, challengeBadgesEnabled],
  );
  const challengeCard = useMemo(() => ({ ...content, id: card.id }), [content, card]);

  /* istanbul ignore next */
  const handleClick = useCallback(
    (challenge: OCSChallengeCard) => () => {
      openExperienceModal(challenge);
    },
    [openExperienceModal],
  );

  /* istanbul ignore next */
  const handleShareClick = useCallback(
    (challenge: OCSChallengeCard) => (event: React.MouseEvent) => {
      event.stopPropagation();
      openShareModal(challenge);
    },
    [openShareModal],
  );

  return (
    <CardWithImage
      title={content.title}
      label={content.creatorName}
      hasCompleted={content.hasCompleted === 2}
      image={image}
      badge={badge}
      isGradient
      action={action}
      shareClick={handleShareClick(challengeCard)}
      onClick={handleClick(challengeCard)}
    />
  );
}
